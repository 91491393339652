<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <h2 class="h2 w-100 text-center mb-10px">ออกแบบมาเพื่อผู้เสียภาษีทุกคน</h2>
                    <p class="p2 text-center sub-text text-gray-medium mb-30px">
                        iTAX เปลี่ยนภาษีที่ซับซ้อน ให้กลายเป็นแอปที่ใช้ง่ายทุกขั้นตอน
                    </p>
                    <div class="d-flex justify-content-center align-items-center pointer">
                        <a class="text-center font-primary link" href="//bit.ly/itaxapp">ดาวน์โหลดฟรี</a>
                        <img src="/assets/images/ico-arrow-right-green.svg" alt="arrow" title="arrow">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
