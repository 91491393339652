<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100 body">
            <div class="row d-flex justify-content-center">
                <div class="col-12 mobile-center p-0 w-100">
                    <h2 class="h2 w-100 text-center mb-30px title">เจ้าของธุรกิจ จะได้อะไรจาก iTAX sme</h2>
                    <div class="row">
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/moneysafe.png" width="100" height="100" alt="money" title="money">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">ลดภาษีสูงสุด</div>
                        </div>
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/img-completed.png" width="100" height="100" alt="completed" title="completed">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">มั่นใจในความถูกต้อง</div>
                        </div>
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/img-checklist.png" width="100" height="100" alt="checklist" title="checklist">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">จัดแผนภาษีตามความต้องการ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
