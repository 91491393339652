<div class="box">
  <div class="itax-body">
    <div class="container position-relative w-100 h-100">
      <div class="wrap-img">
        <picture>
          <source type="image/webp" srcset="/assets/images/landing-page/landing-app.webp">
          <source type="image/jpeg" srcset="/assets/images/landing-page/landing-app.jpg">
          <img srcset="/assets/images/landing-page/landing-app.jpg" alt="landing app" width="1920" height="800" alt="application" title="application">
        </picture>
        <!-- <figure class="bg-img"></figure> -->
      </div>
      <div class="row">
        <div class="col-12 position-relative">
          <h1 class="h1 w-100 text-center mb-10px">App คำนวณภาษี โดยไม่ต้องรู้ภาษี</h1>
          <p class="p1 font-weight-bold w-100 text-center mb-30px">
            แอป iTAX คำนวณภาษีเงินได้บุคคลธรรมดา<br/>
            ได้เงินคืนภาษีสูงสุด ดาวน์โหลดฟรี
          </p>
          <div class="d-flex justify-content-center">
            <div class="pr-15px pointer" (click)="gotoAppPlay()">
              <itax-icon [name]="'playstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
            </div>
            <div class="pointer" (click)="gotoApp()">
              <itax-icon [name]="'appstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
