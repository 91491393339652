<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100 body">
            <div class="row d-flex justify-content-center">
                <div class="col-12">
                    <h2 class="h2 w-100 text-center mb-30px title">iTAX bnk ความร่วมมือจาก 3 องค์กร</h2>
                    <div class="row">
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/img-revenue-dept.png" width="100" height="100" alt="revenue dept" title="revenue dept">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">กรมสรรพากร</div>
                        </div>
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/img-iTAXicon.png" width="100" height="100" alt="iTAX" title="iTAX">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">iTAX</div>
                        </div>
                        <div class="col d-grid">
                            <div class="d-flex justify-content-center pb-15px">
                                <img src="/assets/images/landing-page/img-ktb.png" width="100" height="100" alt="ktb" title="ktb">
                            </div>
                            <div class="p1 d-flex justify-content-center text-center text">ธนาคารกรุงไทย</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
