<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100 body">
            <div class="row justify-content-md-center">
              <div class="col-12 mobile-center">
                <div class="row justify-content-center">
                  <div class="col-2">
                    <img src="/assets/images/landing-page/bubblecheck.png" width="80" height="80" alt="bubble" title="bubble">
                  </div>
                  <div class="col-6">
                    <h3 class="h3">
                      ทำไมต้องใช้บริการ iTAX sme
                    </h3>
                    <p class="p2 sub-text mb-0">
                      iTAX รวบรวมเครือข่ายสำนักงานบัญชีที่มีความถนัดที่หลากหลาย
                      เพราะการเลือกนักบัญชีไม่ควรเลือกเพียงเพราะอยู่แถวบ้าน หรือราคาถูกที่สุด
                      แต่ควรเลือกนักบัญชีที่มีความเชี่ยวชาญและเข้าใจธุรกิจนั้นๆ เป็นอย่างดี
                    </p>
                    <p class="p2 sub-text pt-20px mb-0">
                      SME จึงสามารถติดต่อ iTAX ที่เดียวเพื่อรับบริการแบบครบวงจร ตั้งแต่ขอคำปรึกษา จดบริษัท หานักบัญชี
                      วางแผนภาษี ไปจนถึงสอบบัญชีปิดงบ โดยเฉพาะบริการบัญชีจะมีโปรแกรมบัญชีให้ใช้ฟรีรวมอยู่แล้วด้วย
                    </p>
                    <p class="p2 sub-text pt-20px mb-0">ถ้ามีเจ้าหน้าที่สรรพากรออกหมายเรียก หรือขอตรวจสอบภาษีย้อนหลัง
                      แทนที่คุณจะห่วงหน้าพะวงหลังเหมือนแต่ก่อน
                      ทีมงานมืออาชีพของเราพร้อมจะให้ไปดูแลให้คุณอย่างเต็มที่เอง
                      เพราะเรามั่นใจว่าบัญชีภาษีของคุณถูกต้อง 100% อยู่แล้ว เราจึงสามารถชี้แจงได้ทุกอย่าง โปร่งใส
                      ตรวจสอบได้แน่นอน SME จะได้มุ่งหน้าทำธุรกิจได้อย่างเต็มที่ด้วยความสบายใจ
                      แล้วปล่อยให้นักบัญชีมืออาชีพดูแลเรื่องบัญชีและภาษีให้คุณเอง
                    </p>
                    <p class="p2 sub-text pt-20px mb-0">ติดต่อสอบถามค่าบริการ<br/>
                      โทร. <a href="tel:062-486-9787" class="text-primary">062-486-9787</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center pt-30px">
              <div class="col-12 mobile-center">
                <div class="row justify-content-center">
                  <div class="col-2">
                    <img src="/assets/images/landing-page/man.png" width="80" height="80" alt="person" title="person">
                  </div>
                  <div class="col-6">
                    <h3 class="h3">
                      เราออกแบบบริการจากความต้องการของ SME ก่อนเสมอ
                    </h3>
                    <p class="p2 sub-text mb-0">
                      iTAX จะสอบถามข้อมูลเพื่อทำความเข้าใจลักษณะธุรกิจ SME ของคุณ พร้อมรับฟังความต้องการ
                      จากนั้นจะวิเคราะห์เพื่อจ่ายงานให้สำนักงานบัญชีที่มีความพร้อม
                      และมีความถนัดตรงกับธุรกิจของคุณที่สุด
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
