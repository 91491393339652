<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-10">
                    <div class="row padding-b">
                        <div class="col-sm-12 col-md-6 pb-10px">
                            <img src="/assets/images/landing-page/img-taxrefund.jpg" width="100%" height="100%" class="img" alt="tax refund" title="tax refund">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                คำนวณภาษีถูกต้อง ระดับเศษสตางค์
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                iTAX รองรับการคำนวณภาษีเงินได้บุคคลธรรมดาให้ผู้เสียภาษีมาแล้วนับล้านคน
                                และอัพเดตโปรแกรมคำนวณภาษีตามกฎหมายใหม่เป็นประจำ
                                คุณจึงมั่นใจได้ในประสบการณ์และความถูกต้องของ iTAX
                            </div>
                            <div class="d-flex align-items-center pointer">
                                <a class="text-center font-primary link" href="//bit.ly/itaxapp"
                                   target="_blank">เริ่มคำนวณภาษี</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow" title="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="row padding-b">
                        <div class="d-sm-block d-md-none d-lg-none col pb-10px">
                            <img src="/assets/images/landing-page/img-appplanning.jpg" width="100%" height="100%" class="img" alt="planning" title="planning">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                วางแผนภาษีเองได้ โดยไม่ต้องรู้ภาษี
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                คุณสามารถวางแผนลดหย่อนภาษีได้ด้วยตัวเอง แม้ไม่มีพื้นฐานความรู้ภาษีมาก่อน
                                แค่เลือกโหมดวางแผนภาษีที่ต้องการแล้วทำตามที่ iTAX แนะนำ
                                พร้อมดูตัวเลือกลดหย่อนภาษีที่ถูกคัดมาเพื่อคุณโดยเฉพาะ
                            </div>
                            <div class="d-flex align-items-center pointer">
                                <a class="text-center font-primary link" href="//bit.ly/itaxapp"
                                   target="_blank">ดูตัวเลือกประหยัดภาษี</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow" title="arrow">
                            </div>
                        </div>
                        <div class="d-none d-md-block d-lg-block col-md-6">
                            <img src="/assets/images/landing-page/img-appplanning.jpg" width="100%" height="100%" class="img" alt="planning" title="planning">
                        </div>
                    </div>
                    <div class="row padding-b">
                        <div class="col-sm-12 col-md-6 pb-10px">
                            <img src="/assets/images/landing-page/img-appinde.jpg" width="100%" height="100%" class="img" alt="category" title="category">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                รองรับรายได้ ค่าลดหย่อน ทุกรายการ
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                ไม่ว่าคุณจะประกอบอาชีพอะไร มีรายได้จากทางไหน มีค่าลดหย่อนอะไรบ้าง iTAX
                                รองรับการคำนวณภาษีบุคคลธรรมดาได้ทุกรูปแบบ
                            </div>
                            <div class="d-flex align-items-center pointer">
                                <a class="text-center font-primary link" href="//bit.ly/itaxapp"
                                   target="_blank">เริ่มต้นประหยัดภาษี</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow" title="arrow">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-sm-block d-md-none d-lg-none col pb-10px">
                            <img src="/assets/images/landing-page/img-itaxphones.jpg" width="100%" height="100%" class="img" alt="application" title="application">
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <h2 class="h2 mb-10px">
                                ใช้ฟรีโดยไม่มีเงื่อนไข
                            </h2>
                            <div class="p2 mb-20px sub-text text-gray-medium">
                                iTAX เชื่อว่าผู้เสียภาษีทุกคน คือ ฮีโร่ตัวจริงของประเทศ
                                การทำหน้าที่ผู้เสียภาษีจึงควรง่ายที่สุด สะดวกที่สุด และประหยัดที่สุด
                                เพราะนี่คือสิ่งที่เราเชื่อว่าผู้เสียภาษีทุกคนในประเทศนี้สมควรได้รับ ดังนั้น iTAX
                                จึงมีจุดยืนที่ให้ผู้เสียภาษีทุกคนสามารถใช้แอป iTAX ได้ฟรี โดยไม่มีเงื่อนไขใดๆ ทั้งสิ้น
                                เพื่อตอบแทนการทำหน้าที่ผู้เสียภาษีทุกคน โดย iTAX จะหารายได้จากค่าโฆษณาสินค้าลดหย่อนภาษี
                                และผลิตภัณฑ์อื่นๆ ที่เกี่ยวข้องแทน
                            </div>
                            <div class="d-flex align-items-center pointer">
                                <a class="text-center font-primary link" href="//bit.ly/itaxapp"
                                   target="_blank">ดาวน์โหลด iTAX</a>
                                <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow" title="arrow">
                            </div>
                        </div>
                        <div class="d-none d-md-block d-lg-block col-md-6">
                            <img src="/assets/images/landing-page/img-itaxphones.jpg" width="100%" height="100%" class="img" alt="application" title="application">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
