<div class="box">
    <div class="itax-body">
        <div class="container position-relative w-100 h-100">
            <div class="row d-flex justify-content-center">
                <div class="col-10">
                    <h2 class="h2 w-100 text-center mb-10px">iTAX shop</h2>
                    <p class="p2 text-center sub-text mb-20px text-gray-medium">
                        ซื้อประกันลดหย่อนภาษีบริษัทไหนดี? iTAX จัดอันดับผลประโยชน์จากบริษัทประกันชั้นนำให้แล้ว
                    </p>
                    <div class="d-flex justify-content-center align-items-center pointer">
                        <a class="text-center font-primary link" href="https://shop.itax.in.th">ดูตัวเลือกประหยัดภาษี</a>
                        <img src="/assets/images/ico-arrow-right-green.svg" width="24" height="24" alt="arrow" title="arrow">
                    </div>
                </div>
            </div>
            <div class="wrap-img">
                <picture>
                    <source type="image/webp" srcset="/assets/images/landing-page/img-iTAXshop.webp">
                    <source type="image/jpeg" srcset="/assets/images/landing-page/img-iTAXshop.jpg">
                    <img srcset="/assets/images/landing-page/img-iTAXshop.jpg" alt="iTAX shop" title="iTAX shop">
                </picture>
                <!-- <figure class="bg-img"></figure> -->
            </div>
        </div>
    </div>
</div>
